@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    -webkit-print-color-adjust: exact !important;
}

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

body {
    color: rgb(var(--foreground-rgb));
    background: linear-gradient(
            to bottom,
            transparent,
            rgb(var(--background-end-rgb))
    ) rgb(var(--background-start-rgb));
}

.autoplay-video {
    transition: top 500ms ease-in-out;
}

.autoplay-video video {
    max-height: calc(100% - 2rem);
}

.autoplay-video.video-hidden-bottom {
    top: 100vh;
}

.autoplay-video.video-hidden-top {
    top: -100vh;
}